@media (min-width: 1080px) {
    .cd-primary-nav {
      margin-top: 5px;
  
      > li > a {
        padding: 0 10px !important;
      }
    }
  }
  
  @media (min-width: 960px) and (max-width: 1079px) {
    .cd-primary-nav {
      margin-top: 5px;
  
      > li > a {
        padding: 0 5px !important;
        font-size: 0.9em;
      }
    }
  }
  
  @media (min-width: 769px) {
    .sp--only {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .pc--only {
      display: none;
    }
  
    .cd-primary-nav > li > a {
      padding-left: 15px !important;
    }
  
    .w90-bnr {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  
    /*----- index layout -----*/
  
    .l-top-contener .inner__block, #containar img {
      width: 100%;
    }
  
    .top--topics {
      padding: 0 0 40px;
    }
  
    /* tabbox */
  
    #tabMenu {
      ul {
        width: 100%;
      }
  
      li {
        float: none;
        width: 100%;
        display: none;
  
        &:first-child {
          display: block;
        }
  
        a.active {
          background-color: #ee9223;
          color: #fff;
          font-size: 20px;
        }
      }
    }
  
    .top--topics dl {
      dt, dd {
        padding: 0 5px;
      }
  
      dt {
        display: block;
        width: 100%;
        margin-bottom: 5px;
  
        span {
          width: 100px;
        }
      }
    }
  
    /* tabbox END */
  
    .topic_bnr ul li {
      font-size: 12px;
      padding-left: 15px;
    }
  
    .top--contents {
      padding: 0 5% 40px;
      background-color: #faf4ed;
  
      figure {
        margin-bottom: 1px;
  
        img {
          display: none;
        }
  
        figcaption {
          position: static;
          width: 100%;
          background-color: rgba(255, 255, 255, 1);
          padding: 15px 0 15px 15px;
          text-align: left;
  
          h3 {
            font-size: 16px;
            color: #222;
            font-weight: 500;
            margin: 0;
            background: url(../images/common/arw.png) no-repeat left center;
            background-size: auto 12px;
            padding-left: 15px;
          }
        }
      }
    }
  
    .top--case {
      padding: 40px 0 0;
  
      div.row {
        display: block;
      }
  
      .col-sm-3 {
        border-right: 1px solid #ddd;
        width: 90%;
        margin: 5% auto;
  
        p.num {
          color: #1d97d0;
          font-weight: 600;
          margin: 0 0 15px;
  
          span {
            display: inline-block;
            padding: 3px 10px;
            background-color: #ee9223;
            color: #fff;
          }
        }
  
        h3 {
          font-size: 16px;
          font-weight: 600;
        }
      }
  
      .r_btn {
        &.mt3 {
          margin-top: 2em;
        }
  
        a {
          width: 80%;
          font-size: 16px;
        }
      }
    }
  
    .top--consultation {
      margin: 3em 0 0;
      padding: 3em 0;
  
      p.mincho {
        font-size: 16px;
      }
  
      .box {
        margin: 5px;
        padding: 1px;
        background-color: #fff;
        overflow: hidden;
      }
  
      figure {
        height: 110px;
        overflow: hidden;
        position: relative;
      }
  
      p.last-txt {
        font-size: 14px;
      }
    }
  
    .top--advantage {
      padding: 3em 0;
  
      .row {
        display: block;
      }
  
      .col-md-4 .box {
        margin: 15px auto;
        padding-bottom: 15px;
        min-height: 0;
        width: 90%;
      }
    }
  
    #containar .top--advantage .col-md-4 .box i img {
      width: 59px;
    }
  
    .top--advantage .pos {
      position: static;
      margin: 10px auto;
    }
  
    .top--contact {
      padding: 2em 1em;
      background-color: #faf4ed;
      text-align: center;
  
      .col-sm-4 {
        .box {
          padding: 10px;
        }
  
        h3 span {
          padding: 3px 15px;
        }
  
        p.tel-txt {
          font-size: 28px;
          margin: 5px 0;
        }
      }
    }
  
    .top--flow {
      padding: 3em 0;
  
      .inner__block {
        width: 90%;
      }
    }
  
    .message {
      padding: 4em 0;
  
      .h2__ttl--center {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 60px;
      }
  
      .inner__block {
        width: 92%;
      }
  
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
      }
    }
  
    .foot--bnr {
      max-width: 1180px;
      margin: auto;
  
      .col-sm-6 {
        padding: 5px;
      }
    }
  
    .foot-area {
      .img-wrap {
        max-width: 490px;
        margin: auto;
      }
  
      .inner_right {
        flex: none;
        margin: auto;
        font-size: 19px;
      }
    }
  
    /*----- common style -----*/
  
    .neg__block {
      max-width: 960px;
      margin: -120px auto 0;
      padding: 65px 0 0;
      background-color: #fff;
  
      .inner__block {
        max-width: 810px;
      }
    }
  
    /* ttl */
  
    .h2__ttl--center {
      font-size: 24px;
      margin: 0 0 40px;
      text-align: center;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #111;
      position: relative;
  
      &:after {
        bottom: -60%;
        content: '';
        height: 4px;
        width: 80px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #dcdcdc;
      }
    }
  
    .h3__ttl--left {
      font-size: 2rem;
      font-weight: 800;
      line-height: 1.4;
  
      span.txt-orenge {
        font-size: 1.2em;
        margin-right: 10px;
        font-weight: 300;
      }
    }
  
    .h4__ttl {
      font-size: 16px;
      margin: 15px 0 5px;
    }
  
    .bar__ttl {
      font-size: 1.6rem;
      background-color: #808080;
      color: #fff;
      padding: 10px 0;
      text-align: center;
    }
  
    .sm--ttl {
      font-weight: 600;
      margin-bottom: 25px;
      padding-bottom: 5px;
      background: url(../images/common/line02.png) no-repeat left bottom;
    }
  
    /* font */
  
    .txt-yellow {
      color: #f8dc1f;
    }
  
    .txt-blue {
      color: #1d97d0;
    }
  
    .txt-orenge {
      color: #f17d00;
    }
  
    .txt-red {
      color: #f00;
    }
  
    .txt-pink {
      color: #E73E6D;
    }
  
    .b--txt {
      font-weight: 600;
    }
  
    .marker {
      background: linear-gradient(transparent 60%, #edf268 60%);
    }
  
    .fsize16 {
      font-size: 1.4rem;
    }
  
    .fsize18 {
      font-size: 1.6rem;
    }
  
    .fsize20 {
      font-size: 1.8rem;
      font-weight: 300;
    }
  
    /*　btn　*/
  
    .arw--left {
      background: url(../images/common/arw_01.png) no-repeat left center;
      padding-left: 35px;
      display: inline-block;
    }
  
    .arw--right {
      background: url(../images/common/arw_01.png) no-repeat right center;
      padding-right: 35px;
      display: inline-block;
    }
  
    .submit_btn {
      -webkit-appearance: none;
      background: url(../images/contact/button_bg.png) no-repeat left top;
      background-size: cover;
      border: none;
      line-height: 1;
      padding: 18px 110px;
      font-size: 18px;
      text-align: center;
      font-weight: 600;
      color: #fff;
    }
  
    .r_btn {
      a {
        display: block;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 0;
        text-align: center;
        border: 1px solid;
        border-radius: 30px;
        font-size: 20px;
        box-sizing: border-box;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &.txt-orenge a {
        border-color: #ee9223;
        color: #ee9223;
  
        &:hover {
          border-color: #ffb050;
          color: #ffb050;
        }
      }
    }
  
    a {
      &.button {
        display: block;
        max-width: 400px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid #ee9223;
        background: #fff;
        padding: 18px 0 15px;
        text-align: center;
        color: #ee9223;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        box-sizing: border-box;
        -webkit-transition: all .3s;
        transition: all .3s;
  
        &:after {
          content: "";
          background: url(../images/common/arw.png) no-repeat 80% center;
          position: absolute;
          top: 38%;
          right: 10%;
          width: 12px;
          height: 16px;
          background-size: 12px 16px;
          -webkit-transition: all .3s;
          transition: all .3s;
        }
  
        &:hover {
          background-color: #ee9223;
          color: #fff;
  
          &:after {
            right: 7%;
            filter: alpha(opacity = 0);
            opacity: 0;
          }
        }
      }
  
      &.button02 {
        display: block;
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #ee9223;
        padding: 15px 0 12px;
        text-align: center;
        color: #ee9223;
        font-size: 16px;
        position: relative;
        box-sizing: border-box;
  
        &:after {
          content: "";
          background: url(../images/common/arw.svg) no-repeat 80% center;
          position: absolute;
          top: 38%;
          right: 10%;
          width: 12px;
          height: 12px;
          background-size: 12px 12px;
          -webkit-transition: all .3s;
          transition: all .3s;
        }
  
        &:hover:after {
          right: 7%;
          filter: alpha(opacity = 0);
          opacity: 0;
        }
      }
    }
  
    /* text link */
  
    .text-link {
      display: inline-block;
      color: #ee9223;
      padding: 2px 25px 2px 0;
      background: url(../images/common/arw02.png) no-repeat right center;
      background-size: 14px 14px;
    }
  
    /* list */
  
    .link-group a {
      position: relative;
  
      span {
        margin-left: 20px;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &:hover span {
        margin-left: 30px;
      }
    }
  
    .disc--list li {
      list-style: disc outside;
      margin: 0 0 10px 20px;
      font-size: 0.85em;
    }
  
    .decimal--list li {
      list-style: decimal inside;
      display: list-item;
    }
  
    .katakana--list li {
      list-style: katakana inside;
      display: list-item;
      color: #41bc2d;
    }
  
    .qa--list {
      dt {
        font-size: 1.6rem;
        font-weight: 800;
        padding-left: 30px;
  
        &:before {
          content: "Q.";
          font-size: 2rem;
          display: inline-block;
          color: #ee9223;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
  
      dd {
        margin-bottom: 3em;
        font-size: 1.1em;
        padding: 20px 10px 0 30px;
        line-height: 1.6;
        position: relative;
  
        p:first-child {
          margin-top: 0;
        }
  
        &:before {
          content: "A.";
          font-size: 2rem;
          display: inline-block;
          color: #ddd;
          position: absolute;
          left: 0;
          top: 10px;
        }
      }
    }
  
    /* balloon-1 right */
  
    .balloon-1-right {
      position: relative;
      display: inline-block;
      padding: 20px;
      width: auto;
      min-width: 115px;
      height: auto;
      background: #fff;
      border-radius: 10px;
      z-index: 0;
  
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -14px;
        margin-top: -15px;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 15px 0 15px 15px;
        border-color: transparent transparent transparent #fff;
        z-index: 0;
      }
    }
  
    /*----- sub layout -----*/
  
    #l-sub-contener {
      margin-bottom: 4em;
      padding-top: 2em;
      font-size: 1.3rem;
    }
  
    /* advantage */
  
    .advantage {
      .col-sm-4 a {
        display: block;
        margin: 15px 15px 0;
        border: 2px solid #ee9223;
        padding: 15px;
        text-align: center;
        color: #e58006;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all .3s;
        transition: all .3s;
  
        &:hover {
          background-color: #ee9223;
          color: #fff;
        }
      }
  
      .mtbox {
        margin-top: -1.5em;
        padding: 3em 3% 0;
      }
  
      .btn-info {
        color: #fff;
      }
    }
  
    /* merit */
  
    .merit .col-sm-6 {
      padding: 0 4% 5%;
    }
  
    /* type */
  
    .type {
      .col-sm-4 .box {
        border: 1px solid #ddd;
        padding: 4%;
        margin: 0 10px;
  
        p {
          margin: 0;
        }
      }
  
      .panel-heading p {
        margin: 0;
      }
  
      .panel-body p {
        font-size: 1em;
      }
    }
  
    /* trial */
  
    .trial {
      h3 {
        margin-top: 2em;
      }
  
      h4.h4__ttl {
        margin: 25px 0 0;
      }
  
      .list-inline li {
        display: block;
        width: 100%;
        margin: 1% 0 0 0;
        padding: 4%;
  
        &:nth-child(4), &:nth-child(5) {
          width: 100%;
        }
  
        &:nth-child(4) {
          margin-right: 0;
        }
  
        p {
          margin: 0;
          font-size: 1.4rem;
        }
      }
  
      .katakana--list .list-group-item {
        position: relative;
        color: #f00;
  
        p {
          font-size: 1em;
          padding-left: 20px;
          color: #444;
        }
      }
    }
  
    /* register */
  
    .register {
      .well-lg {
        padding: 5% 4%;
      }
  
      figure {
        float: none;
        width: 20%;
        margin: 0 auto 5%;
  
        img {
          width: 100%;
        }
      }
  
      .balloon-1-right:before {
        display: none;
      }
  
      .txt--area {
        float: none;
        width: 100%;
      }
  
      .list-group-item {
        position: relative;
  
        &:nth-child(even) {
          background-color: #fafafa;
        }
  
        p {
          font-size: 1em;
          padding-left: 0;
          margin: 0;
        }
      }
  
      .num {
        position: static;
      }
    }
  
    /* children */
  
    .children .decimal--list li p {
      margin: 5px 15px;
      font-size: 0.9em;
      color: #444;
    }
  
    /* custody */
  
    .custody .panel-title {
      font-size: 1.7rem;
      font-weight: 800;
    }
  
    /* money */
  
    .money .katakana--list li p {
      margin: 5px;
    }
  
    /* married */
  
    .married h3 {
      margin-top: 2em;
    }
  
    /* pension */
  
    .pension table {
      th {
        text-align: center;
      }
  
      td {
        width: 40%;
        font-size: 0.9em;
  
        &:first-child {
          width: 20%;
        }
      }
    }
  
    /* child */
  
    .child {
      h3 {
        margin-top: 2em;
      }
  
      h4.h4__ttl {
        margin: 25px 0 0;
      }
    }
  
    /* property */
  
    .property {
      h3 {
        margin-top: 2em;
      }
  
      h4.h4__ttl {
        margin: 25px 0 0;
      }
  
      .list-group-item p {
        font-size: 0.9em;
  
        .sp--block {
          font-size: 1.25em;
          display: block;
        }
  
        .num {
          font-size: 1.25em;
        }
      }
    }
  
    /* flow */
  
    .flow {
      .row.mb2 {
        margin-bottom: 0;
      }
  
      p {
        margin: 5px 0;
      }
  
      .fsize18 {
        font-size: 1.4rem;
        margin: 5px 0;
      }
  
      .flow--list li {
        margin-bottom: 3em;
  
        .box {
          background: -moz-linear-gradient(top, #FFF 0%, #EEE);
          background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
          border: 1px solid #DDD;
          width: 96%;
          text-align: center;
          padding: 4% 0 2%;
          margin-bottom: 10px;
          line-height: 1.3;
  
          p {
            margin: 0;
          }
  
          .b--txt.fsize18 {
            font-weight: 500;
            font-size: 15px;
            color: #444;
          }
  
          p.mincho {
            font-size: 2.4rem;
  
            em {
              font-size: 0.8em;
              font-style: normal;
            }
          }
        }
      }
  
      a.btn {
        font-size: 14px;
  
        &:link {
          font-size: 14px;
        }
      }
    }
  
    /* faq */
  
    .faq .qa--list {
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 4%;
      margin-bottom: 2em;
  
      dt {
        font-size: 1.8rem;
        font-weight: 800;
      }
  
      dd {
        margin-bottom: 0;
      }
    }
  
    /* voice */
  
    .voice {
      p.fsize18 {
        width: 96%;
        margin: 0 auto 1em;
      }
  
      .voice--list {
        overflow: hidden;
  
        li {
          float: left;
          width: 31.333%;
          margin: 2% 1% 0;
          border: 1px solid #ddd;
  
          &:nth-child(3n+2) {
            margin: 2% 1% 0;
          }
  
          figure {
            position: relative;
            width: 100%;
            max-height: 340px;
            overflow: hidden;
  
            img {
              width: 100%;
            }
          }
        }
      }
    }
  
    /* cost */
  
    .cost {
      h3 {
        margin-top: 2.5em;
      }
  
      table {
        tr {
          th, td {
            vertical-align: middle;
            text-align: center;
            padding: 2% 1%;
          }
  
          th {
            width: 60%;
            font-size: 0.9em;
          }
  
          td:first-child {
            width: 18%;
          }
        }
  
        &.sptable {
          font-size: 0.95em;
        }
  
        .bg02:nth-child(2) {
          width: 20%;
        }
  
        td.bg02:last-child {
          width: 30%;
        }
      }
    }
  
    /* contact */
  
    .contact {
      .h2__ttl--center {
        line-height: 1.4;
  
        &:after {
          bottom: -20%;
        }
      }
  
      label {
        font-size: 1.1em;
        margin-bottom: 8px;
      }
  
      .btn {
        padding: 8px 40px;
        margin: 10px;
      }
  
      .req {
        margin-left: 10px;
        font-size: 0.8em;
        font-weight: 400;
        color: firebrick;
      }
  
      table.table {
        th, td {
          padding: 2% 4%;
        }
  
        th {
          width: 25%;
        }
      }
    }
  
    /* case-detail */
  
    .case--detail {
      h3.text-center {
        text-align: left;
      }
  
      .h3__ttl--left {
        margin: 2em 0 10px;
      }
  
      p {
        margin-top: 5px;
      }
    }
  
    /* construction */
  
    .construction {
      margin: 10em 0 20em;
  
      p {
        text-align: center;
        font-size: 2rem;
  
        &.b--txt {
          font-size: 3.4rem;
          font-weight: 200;
          color: #ccc;
        }
      }
    }
  
    /* topics
    -------------------------*/
  
    .topic dl {
      display: table;
      width: 100%;
      border-bottom: 1px dashed #ddd;
      padding-bottom: 20px;
      margin-bottom: 25px;
  
      dt, dd {
        padding: 0 5px;
      }
  
      dt {
        display: block;
        width: 100%;
        margin-bottom: 10px;
  
        span {
          width: 100px;
        }
      }
    }
  
    .rea_btn a {
      text-decoration: none;
      display: block;
      width: 100%;
      padding: 15px 35px;
      font-size: 1.8rem;
      border: 2px solid #24a6e3;
      transition: 0.5s;
    }
  
    .topic .rea_btn {
      margin-bottom: 3em;
  
      .col-sm-3 {
        padding: 1% 1%;
      }
  
      a {
        font-size: 1.4rem;
        padding: 4% 0;
      }
    }
  
    .rea_btn a {
      &.news {
        color: #24a6e3;
        border-color: #24a6e3;
  
        &:hover {
          background-color: #24a6e3;
        }
      }
  
      &.consultation {
        color: #ee9223;
        border-color: #ee9223;
  
        &:hover {
          background-color: #ee9223;
        }
      }
  
      &.important {
        color: #fe1800;
        border-color: #fe1800;
  
        &:hover {
          background-color: #fe1800;
        }
      }
  
      &.seminar {
        color: #4a7b06;
        border-color: #4a7b06;
  
        &:hover {
          background-color: #4a7b06;
        }
      }
  
      &:hover {
        color: #fff;
      }
    }
  
    /* topic_detail */
  
    .topic_detail {
      .t_item {
        background: #888888;
        color: #fff;
        padding: 2px 20px;
        margin-right: 10px;
      }
  
      h2 {
        font-size: 2rem;
        padding: 20px 0 10px;
      }
    }
  
    .editor-block {
      margin-bottom: 5em;
  
      h2 {
        font-size: 2.4rem;
      }
  
      h3 {
        font-size: 1.8rem;
      }
  
      h4 {
        font-size: 1.6rem;
      }
  
      p {
        font-weight: 400;
      }
    }

    /* line_yoyaku */
    .ol-list-01{
      li{
        padding-left:30px;
      }
    }

    .line__wrap{
      &-flow{
        padding: 20px;
        &--ttl{
          margin: 0 0 20px;
          font-size: 18px;
        }
        &--link{
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -ms-flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          li{
            img{
              width: auto!important;
              zoom: .5;
            }
          }
        }
        &--attention{
          padding: 30px 15px 15px;
          &---ttl{
            font-size: 14px;
            left: 15px;
          }
        }
        &--cost{
          padding: 30px 15px 15px;
          &---ttl{
            font-size: 14px;
            left: 15px;
          }
          &---contents{
            .items{
              display: block;
              margin: 0;
              dt{
                width: auto;
                margin-bottom: 10px;
                span{
                  font-size: 14px;
                }
              }
              dd{
                width: auto;
                .title{
                  font-size: 14px;
                }
                .list{
                  margin-left: 10px;
                  li{
                    &:not(:last-child){
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
  
  @media (max-width: 480px) {
    /*----- index layout -----*/
  
    .top--consultation {
      figure {
        float: none;
        width: 100%;
        height: 110px;
        overflow: hidden;
        position: relative;
      }
  
      h3 {
        float: none;
        width: 100%;
        min-height: 60px;
      }
    }
  
    /* sitemap */
  
    ul.sitemap--list li {
      margin: 1.5% 4% 0 0;
      width: 45%;
  
      &:nth-child(3n+3) {
        margin-right: 4%;
      }
  
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }